.quiz-analytics-container{
    .tag-header {
        padding: 16px 0;
        .heading-text { 
          color: #0178E6;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 0;
          width: 100%;
        }
      }
      .tag-box {
        width: 100%;
        padding: 35px 45px;
        background-color: #fff;
        .quiz-analysis{
            h3{
                // color: #0178E6;
                // font-weight: 700;
            }
        }
        .quiz-table-wrapper{
            .table-quiz-analysis{
                transform: translateY(0);
                transition: top 0.5s ease;
                margin: 20px 0px 32px;
                table{
                    width: 100%;
                    border-radius: 10px;
                    border-spacing: 0;
                    border-collapse: separate;
                    border: 1px solid #b9b9b9;
                    overflow: hidden;
                    th:not(:last-child),
                    td:not(:last-child) {
                        border-right: 1px solid #b9b9b9;
                    }
                    >thead{
                        th{
                            border-bottom: 1px solid #b9b9b9;
                        }
                    }
                    > thead > tr:not(:last-child) > th,
                    > thead > tr:not(:last-child) > td,
                    > tbody > tr:not(:last-child) > th,
                    > tbody > tr:not(:last-child) > td,
                    > tfoot > tr:not(:last-child) > th,
                    > tfoot > tr:not(:last-child) > td,
                    > tr:not(:last-child) > td,
                    > tr:not(:last-child) > th,
                    > thead:not(:last-child),
                    > tbody:not(:last-child),
                    > tfoot:not(:last-child) {
                      border-bottom: 1px solid #b9b9b9;
                    }
                    th, td {
                        // border: 1px solid black; /* Adds a border to table cells */
                        padding: 8px; /* Adds padding inside each cell */
                        // text-align: left; /* Aligns text to the left */
                      }
                    // th:first-of-type{
                    //     border-radius: 12px;
                    // }
                      .col-1{
                        width: 70%;
                    }
                    .col-2{
                          width: 30%;
                      }
                      td{
                        .quiz-stat-container{
                            min-width: 50px;
                            width: 60%;
                            max-width: 250px;
                            .quiz-stat-cor-wr{
                                display: flex;
                                gap: 2px;
                                margin-bottom: 3px;
                                .correct{
    
                                    background-color: #91cc75;
                                }
                                .wrong{
    
                                    background-color: #ee6666;
                                }
                            }
                            .attempted{
                                background-color: #5470c6;
                                width: 100%;
                            }
                            .st-bar{
                                height: 5px;
                            }
                        }
                        .about-stats{
                            font-size: 12px;
                            .point{
                                width: 5px;
                                height: 5px;
                                border-radius: 50%;
                            }
                        }
                      }
                }
            }
            .table-quiz-analysis-wrapper{

            }
        }
        .quiz-each-user-graph{
            overflow: hidden;
            border-radius: 13px;
            border: 1px solid #b5b5b5;
            .graph-header{
                // border-bottom: 1px solid #b5b5b5;
                background-color: #e5e5e5;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                position: relative;
                z-index: 2;
            }
            .graph-wrapper{
                .graph-collapse{
                    position: relative;
                    z-index: 1;
                    transition: top 0.5s ease-in-out;

                }
            }
        }
        .select-wrapper{
            min-width: 300px;
            width: 40%;
        }
        .loader-wrapper.topic-loader{
            i{
                font-size: 32px;
                
            }
        }
    }
}

